import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {window, document} from 'browser-monads';
import {graphql, useStaticQuery} from 'gatsby';
import {useSwipeable} from 'react-swipeable';
import _ from 'underscore';
import {navigate} from 'gatsby';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Img from 'gatsby-image';

import Header from '../header/header';
import Footer from '../footer/footer';
import styles from './layout.module.scss';
import Sidebar from '../sidebar/sidebar';
import Image from '../image';

const innerHeight = require('ios-inner-height');

export const MENU_LINKS_QUERY = graphql`
  query {
    site {
      siteMetadata {
        menuLinks {
          name
          link
          imageUrl
          sidebarUrl
        }
      }
    }
  }
`;

const Layout = ({children, location}) => {
  const elementRef = useRef();
  let vh = 0;
  const [state, setState] = useState({menuOpened: false});
  const data = useStaticQuery(MENU_LINKS_QUERY);
  const [menuLinks] = useState(data.site.siteMetadata.menuLinks);
  let [url] = menuLinks.filter((link) => link.link === location.pathname);
  const defaultImg = 'black.png';
  const imgIE = "../backgrounds/" + (url ? url.imageUrl: defaultImg);
  const imgNotIE = (location.pathname === '/' && window.innerWidth < 920)
    ? Image(url?.sidebarUrl)
    : Image(url?.imageUrl);
  let throttledCount;
  const firstPage = 0;
  const lastPage = menuLinks.length - 1;
  const [currentPageIndex, setPage] = useState(
    menuLinks.indexOf(
      menuLinks.filter((el) => el.link === location.pathname)[0],
    ),
  );

  const navigateTop = (event) => {
    if (
      !event ||
      (event && event.event && event.event.target.id === 'sidebar')
    ) {
      if (currentPageIndex > firstPage) {
        let nextPageLink = menuLinks[currentPageIndex - 1].link;
        setPage(currentPageIndex - 1);
        navigate(nextPageLink);
      }
    }
  };

  const navigateDown = (event) => {
    if (
      !event ||
      (event && event.event && event.event.target.id === 'sidebar')
    ) {
      if (currentPageIndex < lastPage) {
        let nextPageLink = menuLinks[currentPageIndex + 1].link;
        navigate(nextPageLink);
        setPage(currentPageIndex + 1);
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: (e) => navigateDown(e),
    onSwipedDown: (e) => navigateTop(e),
  });

  const scrollFunction = (event) => {
    if (event.target.id === 'sidebar' || event.target.id === 'stepper' || event.target.parentElement.id === 'sidebar' || event.target.parentElement.id === 'stepper' ) {
      if (event.deltaY > 0) {
        navigateDown();
      } else {
        navigateTop();
      }
    }
  };

  throttledCount = _.debounce(scrollFunction, 400);

  useEffect(() => {
    window.addEventListener('wheel', throttledCount);
    window.addEventListener('resize', () => {
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.tagName) < 0) {
        setHeight();
      }
    });
    document.addEventListener('focusin', disableScroll);
    document.addEventListener('focusout', enableScroll);
    document.addEventListener('blur', enableScroll);
    document.addEventListener('input', (event) => {
      event.preventDefault();
    });
    setHeight();
    window.scroll(0, 0);

    return () => {
      window.removeEventListener('wheel', throttledCount);
      document.removeEventListener('focusin', disableScroll);
      document.removeEventListener('focusout', enableScroll);
      document.removeEventListener('blur', enableScroll);
    }
  }, []);

  const handleToggleMenu = (event, opened) => {
    event.preventDefault();
    setState({menuOpened: opened});
  };

  const disableScroll = () => {
    if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.tagName) > -1) {
      disableBodyScroll(elementRef);
    } else {
      enableScroll();
    }
  };

  const enableScroll = () => {
    // console.log(document.activeElement);
    // // document.getElementById('content').innerHTML = document.activeElement.tagName;
    // document.getElementById('content').style.paddingBottom = 0;
    clearAllBodyScrollLocks(elementRef);
    setHeight();
    // if (document.activeElement.tagName === 'DIV') {
    //   window.scroll(0, 0);
    // }
    // window.scroll(0, 0);
  };

  const setHeight = () => {
    window.scroll(0, 0);
    vh = innerHeight() * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  
  return (
      <Row className={styles.layout}
           id={'body'}
           {...swipeHandlers}
          style={{
            background:`url(${imgIE})`,
            backgroundSize: "cover",
            backgroundPosition: "left center",
          }}
      >
        <div className={styles.layout__image}>
          <Img fluid={imgNotIE} className={styles.layout__image} />
        </div>
        {/* Narrow sidebar */}
        <Col
          xs={location.pathname === '/' ? false : 3}
          lg={2}
          className={`p-0 ${
            location.pathname === '/' || state.menuOpened
              ? styles.homeSidebar
              : ''
          }`}
        >
          <Sidebar
            location={location}
            menuLinks={data.site.siteMetadata.menuLinks}
          />
        </Col>
        {/* End Narrow sidebar */}
        {state.menuOpened ? (
          /* Start Mobile menu */
          <Col xs={12} lg={10} className={`p-0`}>
            <div className={styles.layout__container}>
              <Header
                menuLinks={data.site.siteMetadata.menuLinks}
                onToggle={handleToggleMenu}
                location={location}
              />
              <div className={styles.layout__content}
                   id="content"
                   ref={elementRef}>
                {children}
              </div>
              <Footer />
            </div>
          </Col>
        ) : (
          /* End Mobile menu */
          /* Start Content */
          <Col
            xs={location.pathname !== '/' ? 9 : 12}
            lg={10}
            className={`p-0`}
          >
            <div className={styles.layout__container}>
              <Header
                menuLinks={data.site.siteMetadata.menuLinks}
                onToggle={handleToggleMenu}
                location={location}
              />
              <div className={styles.layout__content}
                   id="content"
                   ref={elementRef}>
                {children}
              </div>
              <Footer />
            </div>
          </Col>
          /* End Content */
        )}
      </Row>
  )
};

export default Layout;
