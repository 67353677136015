import React, {useState} from 'react';
import styles from './header.module.scss';
import logoSrc from '../../images/logo.svg';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';

const MenuBurger = styled.button`
  position: fixed;
  padding: 0;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 6;

  div {
    width: 1.5rem;
    height: 0.2rem;
    background: white;
    border-radius: 5px;
    transform-origin: 1px;
    position: relative;
    transition: opacity 300ms, transform 300ms;
    :first-child {
      transform: ${({opened}) => (opened ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({opened}) => (opened ? '0' : '1')};
    }
    :nth-child(3) {
      transform: ${({opened}) => (opened ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const MenuLinks = styled.nav`
  overflow-x: hidden;
  display: flex;
  z-index: 5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 1.5em;
  height: 100%;
  width: 100%;
  background: #0e0503;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 300ms;
  transform: ${({opened}) =>
    opened ? 'translateX(0)' : 'translate3d(100%, -100%, 0);'};
  padding-top: 60px;
  a {
    margin: 20px 0;
  }
`;

const Header = ({menuLinks, onToggle, location}) => {
  const [state, setState] = useState({menuOpened: false});

  const toggleMenu = (event) => {
    setState({menuOpened: !state.menuOpened});
    onToggle(event, !state.menuOpened);
  };

  return (
    <>
      <header className={styles.header__mobile}>
        {location.pathname !== '/' ? (
          <Link to="/" className={styles.header__mobile__logo}>
            <img src={logoSrc} alt="logo" />
          </Link>
        ) : null}
        <MenuBurger opened={state.menuOpened} onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </MenuBurger>
        {state.menuOpened ? (
          <MenuLinks opened={state.menuOpened}>
            {menuLinks.map((link) => (
              <Link
                to={link.link}
                key={link.name}
                activeClassName={styles.header__mobile_active}
              >
                {link.name}
              </Link>
            ))}
            <div className={styles.menu_footer}>
              <div className={styles.menu_footer_soc}>
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Twitter"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.instagram.com/cellrsociety/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <Link
                to="/privacy-notice/"
                className={styles.menu_footer_item}
                id="menu_privacy"
              >
                Privacy Notice
              </Link>
              <p className={styles.menu_footer_item}>
                Copyright © 2021 Cellar Ventures, Inc. <br /> All rights
                reserved.
              </p>
            </div>
          </MenuLinks>
        ) : null}
      </header>
      <header className={styles.header}>
        {/* <Link to="/">
                    <img src={publicURL} alt="logo" className={styles.header__logo}/>
                  </Link> */}
        {menuLinks.map((link) => (
          <Link
            to={link.link}
            key={link.name}
            activeClassName={styles.header_active}
          >
            {link.name}
          </Link>
        ))}
      </header>
    </>
  );
};

export default Header;
