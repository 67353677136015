import {useMemo} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

const Image = (src) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {internal: {mediaType: {regex: "images/"}}}) {
        edges {
          node {
            relativePath
            childImageSharp {
                fluid(quality: 60, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => data.allFile.edges.find(({node}) => src === node.relativePath),
    [data, src],
  );
  if (match) {
    return match.node.childImageSharp.fluid;
  } else {
    return null;
  }
};

export default Image;
